import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/base.scss'
import '@/tools/rem' // rem适配
import VueLazyload from 'vue-lazyload'
import { preventReClick }from '@/tools/utils'
Vue.prototype.$preventReClick = preventReClick
import VueClipboard from 'vue-clipboard2' // 复制
import './permission' // permission control
import autoimg from '@/plugins/autoImg/index'
import Cookies from 'js-cookie'

import { Message,MessageBox,Select,Option,Input,Loading,Form,FormItem,Dialog,Radio } from 'element-ui'

Vue.use(Radio)
Vue.use(Dialog)
Vue.use(FormItem)
Vue.use(Form)
Vue.use(Loading)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.prototype.$message = Message;

Vue.use(autoimg)

Vue.use(VueClipboard)
import Toast from './components/toast'
Vue.use(Toast)

Vue.use(VueLazyload)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  // 以下代码是安装了预渲染的插件之后自动添加的
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount('#app')
