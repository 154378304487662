import axios from 'axios'
import qs from 'qs'
import { Message, MessageBox } from 'element-ui'
import store from '@/store'
import config from '@/utils/config'
import { getToken, removeToken } from '@/utils/auth'

// request interceptor
axios.interceptors.request.use(config => {
  // Do something before request is sent
  if (store.getters.token) {
    config.headers['Authorization'] = `Bearer ${getToken()}` // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
  }
  config.headers['domain'] = 'mate';
  config.headers['X-Requested-With'] = 'XMLHttpRequest'
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})
const http = (type, ops) => {
  var cUrl = ''
  if (/^(http|https)/.test(ops.url)) {
    cUrl = ops.url
  } else {
    cUrl = config.baseUrl + ops.url
  }
  return new Promise((resolve, reject) => {
    var configs = {
      method: type,
      url: cUrl
    }
    if (type === 'get') {
      configs.params = ops.data
    } else {
      configs.data = qs.stringify(ops.data)
    }
    axios(configs).then(res => {
      // 处理公用逻辑
      if (res.status !== 200 || ops.tips) {
        let type = res.status !== 200 ? 'error' : 'success'
        if (res.status === 200 && res.data && res.data.code === 200) {
          type = 'success'
        } else {
          type = 'warning'
        }
        Message({
          message: res.data && res.data.msg ? res.data.msg : res.statusText,
          type: type,
          duration: 3 * 1000
        })
      } else {
        if (res.data && (
          res.data.code === 40 ||
          res.data.code === 41 ||
          res.data.code === 42 ||
          res.data.code === 43 ||
          res.data.code === 44)) {
          removeToken()
          MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            window.parent.postMessage({ path: '/login' }, '*')
              store.dispatch('user/resetToken').then(() => {
              location.reload()
            })
          })
          // window.parent.postMessage({ path: '/login' }, '*')
        }
      }
      resolve(res.data)
    }).catch(error => {
      Message({
        message: error.message,
        type: 'error',
        duration: 3 * 1000
      })
      reject(error)
    })
  })
}
export default {
  get(ops) {
    return http('get', ops)
  },
  post(ops) {
    return http('post', ops)
  }
}
