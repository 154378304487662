import { login,mobilelogin, register, forget, logout, getUserInfo,wxCallback } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  permissions: [],
  roles: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const mobile = userInfo.mobile.trim()
    return new Promise((resolve, reject) => {
      login(userInfo).then(response => {
        if (response.code != 200) {
          resolve(response)
          return
        }
        const data = response.data
        commit('SET_TOKEN', data.token)
        setToken(response.data.token)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  mobilelogin({ commit }, userInfo) {
    const mobile = userInfo.mobile.trim()
    return new Promise((resolve, reject) => {
      mobilelogin(userInfo).then(response => {
        if (response.code != 200) {
          resolve(response)
          return
        }
        const data = response.data
        commit('SET_TOKEN', data.token)
        setToken(response.data.token)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  bind({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      wxCallback(userInfo).then(response => {
        if (response.code != 200) {
          resolve(response)
          return
        }
        const data = response.data
        commit('SET_TOKEN', data.token)
        setToken(response.data.token)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  register({ commit }, userInfo) {
    const mobile = userInfo.mobile.trim()
    return new Promise((resolve, reject) => {
      register(userInfo).then(response => {
        if (response.code != 200) {
          resolve(response)
          return
        }
        const data = response.data
        commit('SET_TOKEN', data.token)
        setToken(response.data.token)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  forget({ commit }, userInfo) {
    const mobile = userInfo.mobile.trim()
    return new Promise((resolve, reject) => {
      forget(userInfo).then(response => {
        if (response.code != 200) {
          resolve(response)
          return
        }
        const data = response.data
        commit('SET_TOKEN', data.token)
        setToken(response.data.token)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getUserInfo(state.token).then(response => {
        const { data } = response
        if (!data) {
          reject('请重新登录')
        }
        commit('SET_ROLES', ["admin"])
        commit('SET_NAME', data.name)
        commit('SET_AVATAR', data.avatar)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_AVATAR', '')
        removeToken()
        resetRouter()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_AVATAR', '')
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  changeRoles({ commit, dispatch }, role) {
    return new Promise(async resolve => {
      const token = role + '-token'

      commit('SET_TOKEN', token)
      setToken(token)

      const { roles } = await dispatch('getInfo')

      resetRouter()
      // generate accessible routes map based on roles
      const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })

      // dynamically add accessible routes
      router.addRoutes(accessRoutes)

      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
