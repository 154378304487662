<template>
    <div class="auto-img" v-lazy:background-image="src" :style="{'width':w,'height':h}">
      <slot></slot>
    </div>
</template>
<script>
export default {
  name: 'autoImg',
  props: {
    src: {
      type: String
    },
    isrem: Boolean,
    width: null,
    height: null
  },
  data () {
    return {
      scale: 10,
      designWidth: 375,
      defaultW: 1,
      defaultH: 1,
      nWidth: null,
      nHeight: null,
      w: null,
      h: null
    }
  },
  mounted () {
    if (this.width || this.height) {
      if (typeof this.width === 'string') {
        this.w = this.width
      } else {
        this.w = this.width / this.designWidth * this.scale + 'rem'
      }
      if (typeof this.height === 'string') {
        this.h = this.height
      } else {
        this.h = this.height / this.designWidth * this.scale + 'rem'
      }
    } else {
      this.w = this.defaultW + 'rem'
      this.h = this.defaultH + 'rem'
    }
    this.$Lazyload.$on('loaded', ({ bindType, el, naturalHeight, naturalWidth, $parent, src, loading, error }, formCache) => {
      this.nWidth = naturalHeight / this.designWidth * this.scale    //图片原始高
      this.nHeight = naturalWidth / this.designWidth * this.scale    //图片原始高
      if (typeof this.width === 'string') {     //如果宽度是百分比
        this.w = this.width
        if (this.height && typeof this.height !== 'string') {   //如果有设置高，且是px
          this.h = this.height / this.designWidth * this.scale + 'rem'
        }
        if (!this.height) {   //如果没有设置高度
//          this.h = this.nHeight / this.nWidth * this.$el.parentNode.offsetWidth * (this.width.replace('%', '') / 100) / this.scale + 'rem'
          this.h = this.nHeight / this.nWidth * this.$el.parentNode.offsetWidth / this.designWidth * this.scale + 'rem'
        }
      }
      if (typeof this.height === 'string') {
        this.h = this.height
        if (this.width && typeof this.width !== 'string') {
          this.w = this.width / this.designWidth * this.scale  + 'rem'
        }
        if (!this.width) {
//          this.w = this.nWidth / this.nHeight * this.$el.parentNode.offsetHeight * (this.height.replace('%', '') / 100) / this.scale + 'rem'
          this.w = this.nWidth / this.nHeight * this.$el.parentNode.offsetHeight / this.designWidth *  this.scale + 'rem'
        }
      }
      if (typeof this.width !== 'string' && typeof this.height !== 'string') {
        if (!this.width && !this.height) {
          this.w = this.nWidth + 'rem'
          this.h = this.nHeight + 'rem'
        }
        if (!this.width && this.height) {
          this.w = this.nWidth * this.height / this.nHeight + 'rem'
          this.h = this.height / this.designWidth * this.scale + 'rem'
        }
        if (this.width && !this.height) {
          this.w = this.width / this.designWidth * this.scale + 'rem'
          this.h = this.nHeight * this.width / this.nWidth + 'rem'
        }
        if (this.width && this.height) {
          this.w = this.width / this.designWidth * this.scale + 'rem'
          this.h = this.height / this.designWidth * this.scale + 'rem'
        }
        if (this.width && this.height && this.isrem) {
          this.w = this.width + 'rem'
          this.h = this.height + 'rem'
        }
      }
    })
  }
}
</script>
<style type="scss">
.auto-img{
  background-repeat: no-repeat;
  background-size: cover;
  background-origin:border-box;
  background-clip:border-box;
  background-position:50% 50%;
  /*background-image: url('');*/
}
</style>
