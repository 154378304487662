import autoImg from '@/plugins/autoImg/autoImg'

export default {
  /**
   * 每个插件都有的install方法，用于安装插件
   * @param {Object} Vue - Vue类
   * @param {Object} [pluginOptions] - 插件安装配置
   */
  install (Vue, pluginOptions = {}) {
    // 创建"子类"方便挂载
    // Vue.extend(autoImg)
    Vue.component('autoimg', autoImg)
  }
}
