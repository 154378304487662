import router from './router'
import store from './store'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth' // get token from cookie


const whiteList = ['/','/register','/forget','/login', '/home', '/wxLogin','/help','/privacyAgreement','/serviceAgreement','/price','/metaApp','/wxCallback','/bind','/metaScene','/join','/about'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  console.log(to)
  // determine whether the user has logged in
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        next()
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          const { role } = await store.dispatch('user/getInfo')
          // console.log(role.routes)
          // const { permissions } = await store.dispatch('user/getInfo')
          // console.log(permissions)

          // generate accessible routes map based on roles
          const accessRoutes = await store.dispatch('permission/generateRoutes', role)
          // const accessRoutes = await store.dispatch('permission/generateRoutes', permissions)

          // dynamically add accessible routes
          router.addRoutes(accessRoutes)

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true })
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error')
          next(`/login?redirect=${to.fullPath}`)
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.fullPath}`)
    }
  }
})

router.afterEach(() => {
  // finish progress bar
})
