import toast from './index.vue'

export default {
  // 注册Toast
  install(Vue) {
    Vue.prototype.$Toast = (text, opts) => {
      // 设置默认参数，可设置多个
      let defaultOpts = {
        duration: 2000
      }
      opts = Object.assign(defaultOpts, opts);
      // 生成一个Vue的子类
      let toastTpl = Vue.extend(toast);
      // 生成一个该子类的实例
      let tpl = new toastTpl().$mount();
      // 并将此div加入全局挂载点内部
      document.body.appendChild(tpl.$el);
      // 修改提示语
      tpl.text = text;
      // 定时消失
      setTimeout(() => {
        document.body.removeChild(tpl.$el);
      }, opts.duration)
    }
  }
}
