import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)


/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [{
    path: '/redirect',
    hidden: true,
    children: [{
      path: '/redirect/:path*',
      component: () => import('@/views/redirect')
    }]
  },
  {
    path: '/login',
    component: () => import('@/views/login'),
    name: 'login',
    hidden: true
  },
  {
    path: '/register',
    component: () => import('@/views/register'),
    name: 'register',
    hidden: true
  },
  {
    path: '/forget',
    component: () => import('@/views/forget'),
    name: 'forget',
    hidden: true
  },
  {
    path: '/wxLogin',
    component: () => import('@/views/wxLogin'),
    hidden: true
  },
  {
    path: '/wxCallback',
    component: () => import('@/views/wxCallback'),
    hidden: true
  },
  {
    path: '/bind',
    component: () => import('@/views/bind'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true
  },
  { path: '/', redirect: '/home' },
    {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home'),
  },
  {
    path: '/metaApp',
    name: 'metaApp',
    meta: {
      title: 'metaApp',
    },
    component: () => import('@/views/metaApp'),
  },
  {
    path: '/join/:sid',
    name: 'join',
    meta: {
      title: 'join',
    },
    component: () => import('@/views/join'),
  },
  {
    path: '/metaScene',
    name: 'metaScene',
    meta: {
      title: 'metaScene',
    },
    component: () => import('@/views/metaScene'),
  },
  {
    path: '/price',
    name: 'price',
    meta: {
      title: 'price',
    },
    component: () => import('@/views/price'),
  },
  {
    path: '/serviceAgreement',
    name: 'serviceAgreement',
    meta: {
      title: 'serviceAgreement',
    },
    component: () => import('@/views/serviceAgreement'),
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: 'about',
    },
    component: () => import('@/views/about'),
  },
  {
    path: '/privacyAgreement',
    name: 'privacyAgreement',
    meta: {
      title: 'privacyAgreement',
    },
    component: () => import('@/views/privacyAgreement'),
  },
  {
    path: '/help',
    name: 'help',
    meta: {
      title: 'help',
    },
    component: () => import('@/views/help'),
  },
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [{
    path: '/user',
    name: 'user',
    meta: {
      title: 'user',
      roles: ['admin'],
    },
    component: () => import('@/views/user'),
  },
  {
    path: '/square',
    name: 'square',
    meta: {
      title: 'square',
      roles: ['admin'],
    },
    component: () => import('@/views/square'),
  },
  {
    path: '/sceneInfo',
    name: 'sceneInfo',
    meta: {
      title: 'sceneInfo',
      roles: ['admin'],
    },
    component: () => import('@/views/sceneInfo'),
  },
  {
    path: '/createScene',
    name: 'createScene',
    meta: {
      title: 'createScene',
      roles: ['admin'],
    },
    component: () => import('@/views/createScene'),
  },
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  // base: 'manage',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
