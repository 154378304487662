import http from '@/utils/http'
import config from '@/utils/config'
// 获取token
export function getToken() {
  return http.get({
    url: config.platUrl + '/admin/store/gettoken'
  })
}
// 登录
export function login(data) {
  return http.post({
    url: '/api/auth/login',
    data
  })
}
export function mobilelogin(data) {
  return http.post({
    url: '/api/auth/mobile/login',
    data: data
  })
}
export function register(data) {
  return http.post({
    url: '/api/auth/register',
    data
  })
}
export function forget(data) {
  return http.post({
    url: '/api/auth/forget',
    data
  })
}
//重置密码
export function reset(data) {
  return http.post({
    url: '/admin/auth/reset',
    data
  })
}
export function wxCallback(data) {
  return http.get({
    url: '/api/auth/wechat/callback',
    data: data
  })
}
//退出
export function logout() {
  return http.post({
    url: '/admin/auth/logout'
  })
}
//用户详情
export function getUserInfo(token) {
  return http.get({
    url: '/api/user/detail',
    data: { token }
  })
}

