const getters = {
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  userpermissions: state => state.user.permissions,
  roles: state => state.user.roles,
  permission_routes: state => state.permission.routes,
  addRoutes: state => state.permission.addRoutes,
  authList: state => state.app.authList,
  Roles: state => state.app.Roles
}
export default getters
