<template>
  <div class="toast">{{ text }}</div>
</template>

<script>
export default {
  name: "toast",
  data() {
    return {
      text: "",
    };
  },
};
</script>

<style>
.toast {
  background: rgba(24, 151, 119, 0.63);
  color: #ffffff;
  margin: auto auto;
  top: 50%;
  left: 50%;
  padding: 20px;
  position: absolute;
  border-radius: 10px;
  font-size: 20px;
  z-index: 999;
}
</style>
